import React, { useState, useEffect } from 'react';
import {
  AutoComplete, Menu, Dropdown, Input,
} from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, navigate } from 'gatsby';
import useWindowWidth from 'hooks/use-window-width';
import BlueCaliceoLogo from 'images/blue-caliceo-logo.svg';
import SearchIcon from 'images/icons/search-icon.svg';

import './listingOffersHeader.scss';

const { Option } = AutoComplete;

const ListingOffersHeader = (props) => {
  const { centers, location, listedOffers, centerId } = props;
  const [value, setValue] = useState('');

  const [options, setOptions] = useState([]);
  const [activeCenter, setActiveCenter] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);
  useWindowWidth({ setWindowWidth });

  const onSearch = (searchText) => {
    const filteredListedOffers = listedOffers.filter(
      (offer) => offer.title.toUpperCase().includes(searchText.toUpperCase()),
    );

    setOptions(
      searchText.length < 3
        ? []
        : filteredListedOffers,
    );
  };

  const getCenterById = (centerList, _centerId) => {
    const selectedCenter = centerList.find((center) => center.centerId === _centerId);
    setActiveCenter(selectedCenter);
  };

  const onSelect = (data) => {
    navigate(`/produit${data}`);
  };

  const onChange = (data) => {
    setValue(data);
  };

  useEffect(() => {
    getCenterById(centers, centerId);
  }, [location, centers, centerId]);

  const centersMenu = (
    <Menu>
      {centers.map((center) => (
        <Menu.Item key={center.centerId} onClick={() => navigate('/offers')}>
          {center.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  if (windowWidth < 769) {
    return (
      <div className="offers-header--section">
        <div className="container">
          <div className="offers-header--wrapper">
            <Link to="/">
              <img src={BlueCaliceoLogo} className="offer-listing--logo" alt="Caliceo" />
            </Link>
            <div className="search-wrapper">
              <AutoComplete
                value={value}
                style={{
                  width: 130,
                }}
                onSelect={onSelect}
                onSearch={onSearch}
                onChange={onChange}
                dropdownAlign={{ offset: [0, 0] }}
                placeholder="Rechercher"
                className="listing-offers--search"
              >
                {options.map((offer) => (
                  <Option key={`${offer.path.alias}`} value={`${offer.path.alias}`}>
                    {offer.title}
                  </Option>
                ))}
              </AutoComplete>
              <img src={SearchIcon} alt="Search Icon" className="search-wrapper--icon" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="offers-header--section">
      <div className="container">
        <div className="offers-header--wrapper">
          <div>
            {activeCenter && (
              <div className="offers-header--breadcrumbs--wrapper">
                <Link className="offers-link" to="/">{activeCenter?.name}</Link>
                {'  >  '}
                <Link className="offers-link active-offer-link" to="/offers">
                  nos offres
                </Link>
              </div>
            )}
            {activeCenter ? (
              <h2 className="offers-header-title">
                Centre de
                {' '}
                <Link className="offers-link" to="/">{activeCenter?.name}</Link>
              </h2>
            ) : (
              <Dropdown overlay={centersMenu} trigger={['click']}>
                <button type="button" className="dropdown-trigger--btn" onClick={(e) => e.preventDefault()}>
                  Merci de choisir votre centre
                  {' '}
                  <DownOutlined />
                </button>
              </Dropdown>
            )}
          </div>
          <div className="search-wrapper">
            <AutoComplete
              value={value}
              style={{
                width: 200,
              }}
              onSelect={onSelect}
              onSearch={onSearch}
              onChange={onChange}
              dropdownAlign={{ offset: [0, 0] }}
              placeholder="Rechercher"
              className="listing-offers--search"
            >
              {options.map((offer) => (
                <Option key={`${offer.nid}`} value={`${offer.nid}`}>
                  {offer.title}
                </Option>
              ))}
            </AutoComplete>
            <img src={SearchIcon} alt="Search Icon" className="search-wrapper--icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingOffersHeader;
