import React, { useState } from 'react';

import Layout from 'components/layout';

import Reinsurance from 'components/home-group/reinsurance/Reinsurance';
import ListingOffersHeader from './offers-header';
import Offers from './offers';

import TAXONOMY_UNIVERSE_QUERY from 'hooks/use-taxonomy-term';
import TAXONOMY_TAG_QUERY from 'hooks/use-taxonomy-tag';

import { useQuery } from '@apollo/react-hooks';

const ListingOffers = (props) => {
  const { centerId, location } = props;

  const { data, loading, error } = useQuery(TAXONOMY_UNIVERSE_QUERY);

  const { data: tagsData, loading: tagsLoading, error: tagsError } = useQuery(TAXONOMY_TAG_QUERY);

  if (!data || !tagsData) {
    return null;
  }

  const filters = data?.taxonomyTermQuery.entities.map((filter) => ({
    tid: filter.tid,
    name: filter.name,
    categoryText: filter.fieldTexteCategorie,
    secondCategoryText: filter.fieldDeuxiemeTexteCategorie,
    parent: filter.parent[0].entity,
    specialText: filter.fieldTexteSpecial,
  }));

  const tagsList = tagsData?.taxonomyTermQuery.entities.map(
    /**
     *
     * @param {{}} tag
     * @param {number} tag.fieldLigneNumero
     * @param {number} tag.tid
     */
    (tag) => ({
        ...tag,
        lineNumber: tag.fieldLigneNumero,
        key: tag.tid,
        checked: false,
      }
    )
  );
  
  

  return (
    <Layout location={location}>
      
      <ListingOffersHeader centerId={centerId} />
      <Offers
        tagsList={tagsList}
        centerId={centerId}
        filters={filters}
      />
      <Reinsurance location={location} />
    </Layout>
  );
};

export default ListingOffers;
