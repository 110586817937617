import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { selectFiltersAreVisible, selectListedOffers } from 'redux/selectors/offers';
import { selectCenters } from 'redux/selectors/centers';
import { toggleFilters, setOfferListings } from 'redux/actions/offers';

const mapStateToProps = (state) => ({
  filtersAreVisible: selectFiltersAreVisible(state),
  centers: selectCenters(state),
  listedOffers: selectListedOffers(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    toggleFilters,
    setOfferListings,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
