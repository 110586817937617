import React from 'react';
import { Router } from '@reach/router';

import ListingOffers from './ListingOffers';

const OffersPage = (props) => (
  <Router path="/">
    <ListingOffers centerId={props.centerId} exact path="/offers" />
    <ListingOffers centerId={props.centerId} exact path="/offers/:universeId/" />
    <ListingOffers
      centerId={props.centerId}
      path="/offers/:universeId/:uniFilterId"
    />
    <ListingOffers
      centerId={props.centerId}
      path="/offers/:giftType/:whoId/:budgetAmount"
    />
  </Router>
);

export default OffersPage;
