import React, { useEffect, useState } from 'react';

import ListingOffers from 'components/listing-offers';

import { getCenterName } from 'utils/location';
import useSetCenterCookie from 'hooks/use-set-center-cookie';

const OffersPage = (props) => {
  const centerName = getCenterName(props.location);

  const [centerId, setCenterId] = useState('');

  useEffect(() => {
    setCenterId(`centre-${centerName}`);
  }, []);

  useSetCenterCookie(centerId);

  if (!centerId) {
    return null;
  }

  return <ListingOffers centerId={centerId} location={props.location} />;
};

export default OffersPage;
