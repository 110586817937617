import gql from "graphql-tag"

const TAXONOMY_TAG_QUERY = gql`
  {
    taxonomyTermQuery(
      filter: { conditions: [{ field: "vid", value: "tags" }] }
      offset: 0
      limit: 1000
    ) {
      entities {
        ... on TaxonomyTermTags {
          tid
          name
          fieldLigneNumero
          parent {
            entity {
              tid
              name
            }
          }
        }
      }
    }
  }
`;

export default TAXONOMY_TAG_QUERY;
