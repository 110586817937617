import gql from 'graphql-tag';

const OFFERS_LISTING_QUERY = gql`
  query ($universeConditions: [EntityQueryFilterConditionInput], $fieldVariantConditions: [EntityQueryFilterConditionInput], $currentOffset: Int!){
    nodeQuery(
      filter: {
        conditions: $universeConditions
      }
      offset: $currentOffset,
      limit: 1000,
    ) {
      entities {
        ... on NodeOffre {
          nid
          status
          fieldAquaoOid
          title
          fieldUnivers {
            entity {
              tid
              name
            }
          }
          fieldTag {
            entity {
              ... on TaxonomyTermTags {
                tid
                name
                entityId
                fieldLigneNumero
              }
            }
          }
          path {
            alias
          }
          fieldAccrocheTexte {
            processed
          }
          fieldAccrocheFondImage {
            entity {
              ... on MediaImage {
                fieldMediaImage {
                  title
                  derivative(style: LARGE) {
                    url
                  }
                }
              }
            }
          }
          fieldDomainAccess {
            entity {
              entityId
            }
          }
          fieldPoids
          fieldMultipleVariante
          queryFieldVariante(
            filter: {
              conditions: $fieldVariantConditions
            }
          ) {
            entities {
              ... on NodeVariante {
                nid
                fieldAquaoOid
                fieldCode
                fieldPrix
                fieldPrixPromo
                
                fieldDuo
                fieldDureeValidite
                fieldNombreSeance
                fieldConsommablesList {
                  entity {
                    ... on ParagraphConsommable {
                      fieldAffichageFront
                      fieldUtilisationSelection
                      fieldRefEntite {
                        entity {
                          ... on NodeConsommable {
                            title
                            fieldAquaoOid
                            fieldDescription {
                              processed
                            }
                            fieldImage {
                              entity {
                                ... on MediaImage {
                                  fieldMediaImage {
                                    title
                                    url
                                  }
                                }
                              }
                            }
                            fieldDureePrestation
                            fieldPlanifiable
                            fieldDeuxiemeDescription {
                              processed
                            }
                            fieldAquaoTrainingOid
                            fieldAquaoBranchActivite
                            fieldCombobox
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OFFERS_LISTING_QUERY;
