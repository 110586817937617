import gql from 'graphql-tag';

const GIFT_OFFERS_LISTING_QUERY = gql`
    query(
        $universeConditions: EntityQueryFilterInput
        $fieldVariantConditions: EntityQueryFilterInput
    ) {
        nodeQuery(
            filter: $universeConditions
            offset: 0
            limit: 1000
        ) {
            entities {
                ... on NodeOffre {
                    nid
                    status
                    fieldAquaoOid
                    title
                    fieldUnivers {
                        entity {
                            tid
                            name
                        }
                    }
                    fieldTag {
                        entity {
                            tid
                            name
                        }
                    }
                    path {
                        alias
                    }
                    fieldAccrocheTexte {
                        processed
                    }
                    fieldAccrocheFondImage {
                        entity {
                            ... on MediaImage {
                                fieldMediaImage {
                                    title
                                    url
                                }
                            }
                        }
                    }
                    fieldDomainAccess {
                        entity {
                            entityId
                        }
                    }
                    fieldPoids
                    fieldMultipleVariante
                    queryFieldVariante(filter: $fieldVariantConditions) {
                        entities {
                            ... on NodeVariante {
                                nid
                                fieldAquaoOid
                                fieldCode
                                fieldPrix
                                
                                fieldPrixPromo
                                fieldDuo
                                fieldDureeValidite
                                fieldNombreSeance
                                fieldConsommablesList {
                                    entity {
                                        ... on ParagraphConsommable {
                                            fieldAffichageFront
                                            fieldUtilisationSelection
                                            fieldRefEntite {
                                                entity {
                                                    ... on NodeConsommable {
                                                        title
                                                        fieldAquaoOid
                                                        fieldDescription {
                                                            processed
                                                        }
                                                        fieldImage {
                                                            entity {
                                                                ... on MediaImage {
                                                                    fieldMediaImage {
                                                                        title
                                                                        url
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        fieldDureePrestation
                                                        fieldPlanifiable
                                                        fieldDeuxiemeDescription {
                                                            processed
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`;

export default GIFT_OFFERS_LISTING_QUERY;
