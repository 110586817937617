import { useStaticQuery, graphql } from 'gatsby';

const useListingOffersText = () => {
  const { caliceo } = useStaticQuery(graphql`
    query {
      caliceo {
        blockContentById(id:"15") {
          ...on caliceo_BlockContentSimple {
            fieldDescription {
              processed
            }
          }
        }
      }
    }
  `);

  return caliceo.blockContentById.fieldDescription.processed;
};

export default useListingOffersText;
