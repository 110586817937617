import gql from 'graphql-tag';

const TAXONOMY_UNIVERSE_QUERY = gql`
  {
    taxonomyTermQuery(
      filter: { conditions: [{ field: "vid", value: "univers" }] }
      offset: 0
      limit: 1000
    ) {
      entities {
        ... on TaxonomyTermUnivers {
          tid
          name
          parent {
            entity {
              tid
              name
            }
          }
          fieldTexteCategorie {
            processed
          }
          fieldDeuxiemeTexteCategorie {
            processed
          }
          fieldTexteSpecial {
            entity {
              ... on ParagraphTexteCatTag {
                fieldTexteCatTagName {
                  entity {
                    ... on TaxonomyTermTags {
                      tid
                      entityLabel
                    }
                  }
                }
                fieldTexteCatTagDesc
              }
            }
          }
        }
      }
    }
  }
`;

export default TAXONOMY_UNIVERSE_QUERY;
