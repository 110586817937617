import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { navigate } from 'gatsby';
import SEO from 'components/seo';

import { useLazyQuery } from '@apollo/react-hooks';
import useWindowWidth from 'hooks/use-window-width';

import useListingOffersText from 'hooks/use-listing-offers-text';

import CaliceoButton from 'components/buttons/CaliceoButton';
import FilterIcon from 'images/icons/settings.svg';
import { useParams } from '@reach/router';
import { getCenterById } from 'utils/getCentersBy';
import {
  monoDuoFilterConstants,
  typeFilterConstants,
  offerTypeFilterConstants,
} from './constants';
import OfferCard from './offer-card';

import './offers.scss';

import OFFERS_LISTING_QUERY from './hooks';
import GIFT_OFFERS_LISTING_QUERY from './gift-hooks';

const Offers = (props) => {
  const {
  filtersAreVisible,
  actions,
  location,
  centers,
  listedOffers,
  centerId,
  filters,
  tagsList,
  } = props;
  const params = useParams();

  const offerListingsText = useListingOffersText();


  const [offersConditions, setOffersConditions] = useState([
  { field: 'type', value: 'offre' },
  ]);
  const [fieldVarianteConditions, setFieldVarianteConditions] = useState([]);

  const [isGift, setIsGift] = useState(false);
  const [giftOffersConditions, setGiftOffersConditions] = useState({});
  const [giftFieldVarianteConditions, setGiftFieldVarianteConditions] = useState({});


  // const [getGiftOfferListings, giftOffersRes] = useLazyQuery(GIFT_OFFERS_LISTING_QUERY);
  const checkIsGift = (params) => (
  params?.giftType && parseInt(params?.giftType) >= 0
  && params?.whoId && parseInt(params?.whoId) >= 0
  && params?.budgetAmount && parseInt(params?.budgetAmount) > 0
  );

  // const [selectedCentre, setSelectedCentre] = useState('');
  const [centerObject, setCenterObject] = useState(null);
  const [selectedUniverse, setSelectedUniverse] = useState(null);
  const [selectedUniFilter, setSelectedUniFilter] = useState(null);
  const [tagFiltersToDisplay, setTagFiltersToDisplay] = useState([]);
  const [promosSelected, setPromosSelected] = useState(false);
  const [levelThreeFilters, setLevelThreeFilters] = useState([]);
  const [monoDuoFilters, setMonoDuoFilters] = useState(monoDuoFilterConstants);
  const [typeFilters, setTypeFilters] = useState(typeFilterConstants);
  const [offerTypeFilters, setOfferTypeFilters] = useState(offerTypeFilterConstants);
  const [tagFilters, setTagFilters] = useState(tagsList);
  const [windowWidth, setWindowWidth] = useState(0);
  const [nbOffer, setNbOffer] = useState(0);
  
  const [offersView, setOffersView] = useState([]);
  

  const [offset, setOffset] = useState(0);

  useWindowWidth({ setWindowWidth });

  function handleChange(value) {
  //console.log(`selected ${value}`);
  }

  useEffect(() => {
  setTagFiltersToDisplay([]);
  }, []);

  useEffect(() => {
    var cpt = 0;
    listedOffers.map(function(item) {
      if((item.fieldTag[0]?.entity.tid === 25) && (item.status === true)) {
        cpt ++;
      }
    })
    setNbOffer(cpt);

    //const myData = listedOffers.sort((a, b) => a.fieldPoids - b.fieldPoids ? 1 : -1);

    listedOffers.sort((a, b) => {
      if(a.fieldPoids === null) return 1;
      if(b.fieldPoids === null) return -1;

      if (b.fieldPoids > a.fieldPoids){
        return -1
      }
      if (b.fieldPoids < a.fieldPoids){
          return 1
      }
      return 0
    });
    setOffersView(listedOffers);
    //console.log(listedOffers);

  }, [listedOffers]);
  
  
  

  useEffect(() => {
    const sliceUrl = () => {
      const getFilter = (name) => filters.find(
      (filter) => filter.name === decodeURIComponent(name),
      );
      if (checkIsGift(params)) {
      setIsGift(true);
      const whoTag = parseInt(params?.whoId);
      const giftTypeTag = parseInt(params?.giftType);
      const budget = parseInt(params?.budgetAmount);
      const filterNode = {
        conditions: [
        { operator: 'EQUAL', field: 'field_domain_access', value: [`${centerObject?.domainAccess}`] },
        ],
      };
      /** 0 allow all */
      if (giftTypeTag !== 0 || whoTag !== 0) {
        filterNode.conjunction = 'AND';
        if (giftTypeTag !== 0) {
        filterNode.conditions.push({ operator: 'EQUAL', field: 'field_tag', value: [giftTypeTag] });
        }
        if (whoTag !== 0) {
        filterNode.conditions.push({ operator: 'EQUAL', field: 'field_tag', value: [whoTag] });
        }
      }
      setGiftOffersConditions(filterNode);
      setGiftFieldVarianteConditions({
        conjunction: 'OR',
        groups: [
        {
          conjunction: 'AND',
          conditions: [
          { operator: 'SMALLER_THAN_OR_EQUAL', field: 'field_prix', value: [budget] },
          { operator: 'IS_NOT_NULL', field: 'field_prix' },
          ],
        },
        {
          conjunction: 'AND',
          conditions: [
          { operator: 'SMALLER_THAN_OR_EQUAL', field: 'field_prix_promo', value: [budget] },
          { operator: 'IS_NOT_NULL', field: 'field_prix_promo' },
          ],
        },
        ],
      });
      return;
      }

      if (params?.universeId) {

      if (params?.universeId === 'Promo') {
        setPromosSelected(true);
        setSelectedUniverse('');
        setSelectedUniFilter('');
      } else {
        setPromosSelected(false);
        setSelectedUniverse(getFilter(params?.universeId));
        setSelectedUniFilter(getFilter(params?.uniFilterId));
      }
      } else {
      setSelectedUniverse('');
      setSelectedUniFilter('');
      }
    };
    sliceUrl();
  }, [location]);

  const optionsOffer = {
  onCompleted: (data) => {
    actions.setOfferListings(data?.nodeQuery?.entities);
  },
  };
  const [getOfferListings, offersRes] = !isGift ? useLazyQuery(OFFERS_LISTING_QUERY, optionsOffer) : useLazyQuery(GIFT_OFFERS_LISTING_QUERY, optionsOffer);

  useEffect(() => {
  if (!isGift) {
    getOfferListings({
    variables: {
      universeConditions: offersConditions,
      fieldVariantConditions: fieldVarianteConditions,
      currentOffset: offset,
    },
    });
  }
  }, [offersConditions, fieldVarianteConditions, offset]);


  useEffect(() => {
  if (isGift) {
    getOfferListings({
    variables: {
      universeConditions: giftOffersConditions,
      fieldVariantConditions: giftFieldVarianteConditions,
      currentOffset: offset,
    },
    });
  }
  }, [giftOffersConditions, giftFieldVarianteConditions, offset]);

  const getIdHierarchy = (categories, catId) => {
  const idsToAdd = categories.filter((i) => i.parent?.tid === catId).map((i) => i.tid);
  // eslint-disable-next-line no-unused-vars
  return [catId].concat(...idsToAdd.map((i) => getIdHierarchy(categories, i)));
  };

  const createUniverseConditions = useCallback(
  () => {
    const hasSelectedLevelThree = levelThreeFilters?.some((filter) => filter.checked);
    const hasSelectedTagFilters = tagFilters?.some((filter) => filter.checked);
    const universeConditions = [
    { field: 'type', value: 'offre' },
    (centerObject ? { field: 'field_domain_access', value: `${centerObject.domainAccess}` } : []),
    ((selectedUniverse && !selectedUniFilter && !hasSelectedLevelThree) ? { operator: 'IN', field: 'field_univers', value: getIdHierarchy(filters, selectedUniverse?.tid) } : []),
    ((selectedUniverse && selectedUniFilter && !hasSelectedLevelThree) ? { operator: 'IN', field: 'field_univers', value: getIdHierarchy(filters, selectedUniFilter?.tid) } : []),
    (promosSelected ? { field: 'field_tag', value: '25' } : []),
    (hasSelectedLevelThree ? { operator: 'IN', field: 'field_univers', value: levelThreeFilters.flatMap((filter) => (filter.checked ? `${filter.tid}` : [])) } : []),
    (hasSelectedTagFilters ? { operator: 'IN', field: 'field_tag', value: tagFilters.flatMap((filter) => (filter.checked ? filter.tid : [])) } : []),
    ];
    return universeConditions.flat();
  },
  [location, selectedUniverse, selectedUniFilter, promosSelected, centerObject, levelThreeFilters, tagFilters, offset],
  );

  useEffect(() => {
  setOffersConditions(createUniverseConditions());
  }, [location, selectedUniverse, selectedUniFilter, promosSelected, centerObject]);

  useEffect(() => {
  setCenterObject(getCenterById(centers, centerId));
  }, [centerId, centers]);

  const checkPromoOffers = (offersList) => offersList?.some(
  (offer) => offer.fieldTag[0]?.entity.tid === 25,
  );

  const selectUniverse = (filter) => {
  setSelectedUniverse(filter);
  setSelectedUniFilter(null);
  setLevelThreeFilters(null);
  navigate(`/offers/${filter.name}`);
  };

  const resetAllFilters = () => {
  navigate('/offers');
  setSelectedUniverse(null);
  setSelectedUniFilter(null);
  setLevelThreeFilters(null);
  };

  const selectLevelTwo = (filter) => {
  setSelectedUniFilter(filter);
  navigate(`/offers/${selectedUniverse?.name}/${filter.name}`);
  };

  const checkFilterHasNextLevel = (id) => filters.some(
  (filter) => filter.parent && filter.parent?.tid === id,
  );

  const promoButtonClicked = () => {
  setPromosSelected(!promosSelected);
  setSelectedUniFilter(null);
  setSelectedUniverse(null);
  navigate('/offers/Promo');
  };

  const applyFilters = () => {
  const fieldVariantConditions = () => ([
    { operator: 'IN', field: 'field_duo', value: monoDuoFilters.flatMap((filter) => (filter.checked ? filter.key : [])).join() },
    { operator: 'IN', field: 'field_variante_type', value: typeFilters.flatMap((filter) => (filter.checked ? filter.key : [])).join() },
  ]);
  setOffersConditions(createUniverseConditions());
  setFieldVarianteConditions(fieldVariantConditions());
  actions.toggleFilters();
  };

  const toggleFilterChecked = (filterList, filterId, filterGroupSetter) => {
  const newFilters = filterList.map((filterItem) => {
    if (filterItem.key === filterId) {
    return ({
      ...filterItem,
      checked: !filterItem.checked,
    });
    }
    return filterItem;
  });
  filterGroupSetter(newFilters);
  };

  const handleCheckAllFilters = (filterList, filterGroupSetter) => {
  const newFilters = filterList.map((filterItem) => ({
    ...filterItem,
    checked: true,
  }));
  filterGroupSetter(newFilters);
  };

  const getOfferListingTexts = (universeFilterSelected, levelTwoFilterSelected) => {
  if (levelTwoFilterSelected && levelTwoFilterSelected.categoryText?.processed) {
    return {
    categoryText: levelTwoFilterSelected.categoryText?.processed,
    secondCategoryText: levelTwoFilterSelected.secondCategoryText?.processed,
    };
  }
  if (universeFilterSelected && universeFilterSelected.categoryText?.processed) {
    return {
    categoryText: universeFilterSelected.categoryText?.processed,
    secondCategoryText: universeFilterSelected.secondCategoryText?.processed,
    };
  }
  return {
    categoryText: offerListingsText,
    secondCategoryText: null,
  };
  };

  const toggleLevelThreeFilters = (filterId) => {
  const updatedFilterList = levelThreeFilters.map(
    (filterItem) => (
    (filterItem.tid === filterId)
      ? { ...filterItem, checked: !filterItem.checked } : filterItem),
  );
  setLevelThreeFilters(updatedFilterList);
  };

  useEffect(() => {
  if (selectedUniFilter) {
    const currentLevelThreeFilters = filters.filter(
    (oneFilter) => oneFilter.parent?.tid === selectedUniFilter?.tid,
    ).map((_filter) => ({
    ..._filter,
    checked: false,
    }));

    setLevelThreeFilters(currentLevelThreeFilters);
  }
  }, [selectedUniFilter]);

  useEffect(() => {
  const filtersLine = [];
  // const tagFiltersToDisplay = [];
  tagFilters
    .filter((filter) => filter.lineNumber !== 99)
    .sort((a, b) => a.lineNumber - b.lineNumber)
    .map((filter, index) => {
    if (index === 0) {
      filtersLine.push(filter.lineNumber);
    }
    if (filtersLine.indexOf(filter.lineNumber) === -1) {
      setTagFiltersToDisplay((old) => [...old, (<div className="filter-buttons-row--line-break"></div>)]);
      filtersLine.push(filter.lineNumber);
    }
    const checkbox = (
      <Checkbox
      className={`filter-checkboxes line-${filter.lineNumber}`}
      checked={filter.checked}
      key={filter.tid}
      onChange={() => {
        toggleFilterChecked(tagFilters, filter.tid, setTagFilters);
        setTagFiltersToDisplay([]);
      }}
      >
      {filter.name}
      </Checkbox>
    );
    setTagFiltersToDisplay((old) => [...old, checkbox]);
    return filter;
    });
  }, [tagFilters]);

  if (!offersRes?.data) {
  return null;
  }
  
  const regex = /(<([^>]+)>)/ig;
  const result_descr = getOfferListingTexts(selectedUniverse, selectedUniFilter).categoryText.replace(regex, '').substr(0, 200);
  
  var meta_titre = "Offres ";
  if(tagFilters[0].checked) {
  meta_titre = "Offrir ";
  }
  
  if(promosSelected) {
  meta_titre = "Promo : ";
  }
  
  if(selectedUniverse) {
  if(selectedUniverse.tid == 1) {
    meta_titre = "Piscine SPA ";
  }else if(selectedUniverse.tid == 2) {
    if(!selectedUniFilter) {
    meta_titre = "SPA ";
    }else {
    if(selectedUniFilter.tid == 36) {
      meta_titre = "SPA Rituel ";
    }else {
      meta_titre = selectedUniFilter.name + " ";
    }
    }
  }else {
    meta_titre = "Aquaforme/Aquagym ";
  }
  }
  
  if(levelThreeFilters) {
  if(levelThreeFilters.length > 4) {
    if(levelThreeFilters[4].checked) {
    meta_titre += "Femme Enceinte ";
    }
  }
  }
  
  if(monoDuoFilters[1].checked && !monoDuoFilters[0].checked) {
  meta_titre += "Duo/Couple ";
  }
  
  meta_titre += "à "+ centerObject.name +" ";

  if (windowWidth < 769) {
  return (
    <div className="container">
    <h1 className="offers-section--title">
      Nos offres de
      <br />
      <div className="offers-link">
      <span>{` ${centerObject ? centerObject.name : ''}`}</span>
      </div>
    </h1>
    <div
      className="offers-text"
      dangerouslySetInnerHTML={{ __html: getOfferListingTexts(selectedUniverse, selectedUniFilter).categoryText }}
    />
    <hr className="offers-divider" style={{ display: filtersAreVisible ? 'block' : 'none' }} />
    <div className={`offers-filters-section ${filtersAreVisible ? 'filters-expanded' : ''}`}>
      <div className={`${filtersAreVisible ? 'container' : ''}`}>
      <div>
        <div className="filter-header-block" style={{ display: filtersAreVisible ? 'flex' : 'none' }}>
        <div className="filter-header">
          <h4 className="filter-header--title">Filtrer</h4>
        </div>
        <CloseOutlined className="close-filters--btn" onClick={() => actions.toggleFilters()} />
        </div>
        <div className="small-filter-buttons-row">
        <img
          src={FilterIcon}
          role="presentation"
          alt="Filter settings"
          onClick={() => actions.toggleFilters()}
          className="filter-icon-btn"
          style={{ display: filtersAreVisible ? 'none' : 'flex' }}
          type="button"
        />
        {(nbOffer !== 0) && (
          <button type="button" onClick={() => promoButtonClicked()} className={`small-primary-filters-btn promo-button ${promosSelected ? 'active-filter' : ''}`}>PROMOS</button>
        )}
        
        {filters.map((filter) => {
          if (filter.parent) return null;
          if (filter.name.includes('recharge-vitalite') || filter.name.includes('recharge-tempo')) return null;
          return (
          <button
            type="button"
            className={`small-filter-btn ${selectedUniverse?.tid === filter.tid ? 'active-filter' : ''}`}
            onClick={() => selectUniverse(filter)}
            key={filter.tid}
          >
            {filter.name}
          </button>
          );
        })}
        </div>
        {checkFilterHasNextLevel(selectedUniverse?.tid) && (
        <div className="checkbox-filter-buttons-row">
          {filters.map((filter) => {
          if (filter.parent?.tid === selectedUniverse?.tid) {
            return (
            <div className={`secondary-filters-btn v2 ${selectedUniFilter?.tid === filter.tid ? 'active-filter' : ''}`}>
              <a
                onClick={() => selectLevelTwo(filter)}
                key={filter.tid}
                href={`/offers/SPA/${filter.name}`}
                title={`SPA - ${filter.name}`}
                className={`active-filter-link ${selectedUniFilter?.tid === filter.tid ? 'active-filter-link-active' : ''}`}
                >
            {filter.name}
            </a>
            <CloseOutlined
              onClick={(e) => {
              e.stopPropagation();
              if (selectedUniFilter?.tid === filter.tid) {
                setSelectedUniFilter(null);
              }
              }}
            />
            </div>
            );
          }
          return null;
          })}
        </div>
        )}
        <div style={{ display: filtersAreVisible ? 'block' : 'none' }}>
        {checkFilterHasNextLevel(selectedUniFilter?.tid) && (
          <div className="filters-row--wrapper level-three--filters">
          <div className="checkbox-filter-buttons-row">
            {levelThreeFilters && levelThreeFilters.map((filter) => (
            <Checkbox
              className="filter-checkboxes"
              key={filter.tid}
              checked={filter.checked}
              onChange={() => toggleLevelThreeFilters(filter.tid)}
            >
              {filter.name}
            </Checkbox>
            ))}
          </div>
          <button
            onClick={() => handleCheckAllFilters(levelThreeFilters, setLevelThreeFilters)}
            type="button"
            className="select-all-options--btn"
          >
            TOUT SÉLECTIONNER
          </button>
          </div>
        )}
        {selectedUniverse?.name === 'SPA' && (
          <div className="filters-row--wrapper">
          <div className="filter-buttons-row">
            {monoDuoFilters.map((filter) => (
            <Checkbox
              className="filter-checkboxes"
              checked={filter.checked}
              key={filter.key}
              onChange={() => toggleFilterChecked(monoDuoFilters, filter.key, setMonoDuoFilters)}
            >
              {filter.name}
            </Checkbox>
            ))}
          </div>
          <button
            onClick={() => handleCheckAllFilters(monoDuoFilters, setMonoDuoFilters)}
            type="button"
            className="select-all-options--btn"
          >
            TOUT SÉLECTIONNER
          </button>
          </div>
        )}
        <div className="filters-row--wrapper">
          <div className="checkbox-filter-buttons-row">
          {typeFilters.map((filter) => (
            <Checkbox
            className="filter-checkboxes"
            checked={filter.checked}
            key={filter.key}
            onChange={() => toggleFilterChecked(typeFilters, filter.key, setTypeFilters)}
            >
            {filter.name}
            </Checkbox>
          ))}
          </div>
          <button
          onClick={() => handleCheckAllFilters(typeFilters, setTypeFilters)}
          type="button"
          className="select-all-options--btn"
          >
          TOUT SÉLECTIONNER
          </button>
        </div>
        <div className="filters-row--wrapper">
          <div className="checkbox-filter-buttons-row">
          {offerTypeFilters.map((filter) => (
            <Checkbox
            className="filter-checkboxes"
            checked={filter.checked}
            key={filter.key}
            onChange={() => toggleFilterChecked(
              offerTypeFilters, filter.key, setOfferTypeFilters,
            )}
            >
            {filter.name}
            </Checkbox>
          ))}
          </div>
          <button
          onClick={() => handleCheckAllFilters(offerTypeFilters, setOfferTypeFilters)}
          type="button"
          className="select-all-options--btn"
          >
          TOUT SÉLECTIONNER
          </button>
        </div>
        <div className="filters-row--wrapper">
          <div className="checkbox-filter-buttons-row">
          {tagFilters.map((filter) => (
            <Checkbox
            className="filter-checkboxes"
            checked={filter.checked}
            key={filter.tid}
            onChange={() => toggleFilterChecked(
              tagFilters, filter.tid, setTagFilters,
            )}
            >
            {filter.name}
            </Checkbox>
          ))}
          </div>
          <button
          onClick={() => handleCheckAllFilters(tagFilters, setTagFilters)}
          type="button"
          className="select-all-options--btn"
          >
          TOUT SÉLECTIONNER
          </button>
        </div>
        <div className="btn-row---wrapper">
          <CaliceoButton
          primaryButton
          showIcon={false}
          classNames="mx-auto mb-2 promo-offer--btn small-primary-caliceo--button"
          onClick={() => applyFilters()}
          title="C’est parti !"
          />
          <CaliceoButton
          primaryButton
          showIcon={false}
          onClick={() => resetAllFilters()}
          type="button"
          title="Réinitialiser"
          classNames="mx-auto mb-3 small-primary-caliceo--button"
          />
        </div>
        </div>
      </div>
      </div>
    </div>
    {(checkPromoOffers(offersView) && (nbOffer !== 0)) && (
      <>
      <div className="offers--row">
        {offersView.map((entity) => {
        if (entity.fieldTag[0]?.entity.tid !== 25 || !entity.status) return null;
        return (
          <OfferCard key={entity.nid} offer={entity} isPromo />
        );
        })}
      </div>
      </>
    )}
    {(!promosSelected && offersView.length > 0) && (
      <>
      <h2 className="offer-listing--header">
        Nos offres dédiées pour vous
      </h2>
      <div className="offers--row">
        {offersView.map((entity) => {
        if (!entity.status) return null;
        return (
          <OfferCard key={entity.nid} offer={entity} isPromo={false} />
        );
        })}
      </div>
      </>
    )}
    {getOfferListingTexts(selectedUniverse, selectedUniFilter).secondCategoryText && (
      <div
      className="offers-text"
      dangerouslySetInnerHTML={{ __html: getOfferListingTexts(selectedUniverse, selectedUniFilter).secondCategoryText }}
      />
    )}
    <SEO title={meta_titre} description={result_descr} />
    </div>
  );
  }

  return (
  <div className="container">
    <div className={`offers-filters-section ${filtersAreVisible ? 'filters-expanded' : ''}`}>
    <div className={`${filtersAreVisible ? 'container' : ''}`}>
      <h1 className="offers-section--title">
      Nos offres
      {` ${centerObject ? centerObject.name : ''}`}
      </h1>
      <hr className="offers-divider" style={{ display: filtersAreVisible ? 'block' : 'none' }} />
      <div>
      <div className="filter-header-block" style={{ display: filtersAreVisible ? 'flex' : 'none' }}>
        <button
        onClick={() => resetAllFilters()}
        type="button"
        className="toggle-filters-btn reset-filters--btn"
        >
        RÉINITIALISER
        </button>
        <div className="filter-header">
        <h3 className="filter-header--title">Filtres</h3>
        <p className="filter-header--desc">
          Faites le choix qui correspond à vos attentes
        </p>
        </div>
        <CloseOutlined className="close-filters--btn" onClick={() => {actions.toggleFilters()}} />
      </div>
      <div className="filter-buttons-row">
        {(nbOffer !== 0) && (
          <a href="/offers/Promo" onClick={() => promoButtonClicked()} className={`primary-filters-btn promo-button ${promosSelected ? 'active-filter' : ''}`}>PROMOS</a>
        )}
        
        {filters.map((filter) => {
        if (filter.parent) return null;
        if (filter.name.includes('recharge-vitalite') || filter.name.includes('recharge-tempo')) return null;

        return (
          <a
          href={`/offers/${filter.name}`}
          title={`${filter.name}`}
          className={`primary-filters-btn ${selectedUniverse?.tid === filter.tid ? 'active-filter' : ''}`}
          onClick={() => selectUniverse(filter)}
          key={filter.tid}
          >
          {filter.name}
          </a>
        );
        })}
        {!isGift && (
        <button
          onClick={() => actions.toggleFilters()}
          style={{ display: filtersAreVisible ? 'none' : 'flex' }}
          type="button"
          className="toggle-filters-btn"
        >
          + De filtres
        </button>
        )}
      </div>
      {checkFilterHasNextLevel(selectedUniverse?.tid) && (
        <div className="filter-buttons-row">
        {filters.map((filter) => {
          if (filter.parent?.tid === selectedUniverse?.tid) {
          return (
            <div className={`secondary-filters-btn v2 ${selectedUniFilter?.tid === filter.tid ? 'active-filter' : ''}`}>
              <a
                onClick={() => selectLevelTwo(filter)}
                key={filter.tid}
                href={`/offers/SPA/${filter.name}`}
                title={`SPA - ${filter.name}`}
                className={`active-filter-link ${selectedUniFilter?.tid === filter.tid ? 'active-filter-link-active' : ''}`}
                >
            {filter.name}
            </a>
            <CloseOutlined
              onClick={(e) => {
              e.stopPropagation();
              if (selectedUniFilter?.tid === filter.tid) {
                setSelectedUniFilter(null);
              }
              }}
            />
            </div>
            
          );
          }
          return null;
        })}
        </div>
      )}
      <div style={{ display: filtersAreVisible ? 'block' : 'none' }}>
        {checkFilterHasNextLevel(selectedUniFilter?.tid) && (
        <div className="filters-row--wrapper level-three--filters">
          <div className="filter-buttons-row">
          {levelThreeFilters && levelThreeFilters.map((filter) => (
            <Checkbox
            className="filter-checkboxes"
            key={filter.tid}
            checked={filter.checked}
            onChange={() => toggleLevelThreeFilters(filter.tid)}
            >
            {filter.name}
            </Checkbox>
          ))}
          </div>
          <button
          onClick={() => handleCheckAllFilters(levelThreeFilters, setLevelThreeFilters)}
          type="button"
          className="select-all-options--btn"
          >
          TOUT SÉLECTIONNER
          </button>
        </div>
        )}
        {selectedUniverse?.name === 'SPA' && (
        <div className="filters-row--wrapper">
          <div className="filter-buttons-row">
          {monoDuoFilters.map((filter) => (
            <Checkbox
            className="filter-checkboxes"
            checked={filter.checked}
            key={filter.key}
            onChange={() => toggleFilterChecked(monoDuoFilters, filter.key, setMonoDuoFilters)}
            >
            {filter.name}
            </Checkbox>
          ))}
          </div>
          <button
          onClick={() => handleCheckAllFilters(monoDuoFilters, setMonoDuoFilters)}
          type="button"
          className="select-all-options--btn"
          >
          TOUT SÉLECTIONNER
          </button>
        </div>
        )}
        <div className="filters-row--wrapper">
        <div className="filter-buttons-row">
          {typeFilters.map((filter) => (
          <Checkbox
            className="filter-checkboxes"
            checked={filter.checked}
            key={filter.key}
            onChange={() => toggleFilterChecked(typeFilters, filter.key, setTypeFilters)}
          >
            {filter.name}
          </Checkbox>
          ))}
        </div>
        <button
          onClick={() => handleCheckAllFilters(typeFilters, setTypeFilters)}
          type="button"
          className="select-all-options--btn"
        >
          TOUT SÉLECTIONNER
        </button>
        </div>
        <div className="filters-row--wrapper">
        <div className="filter-buttons-row">
          {offerTypeFilters.map((filter) => (
          <Checkbox
            className="filter-checkboxes"
            checked={filter.checked}
            key={filter.key}
            onChange={() => toggleFilterChecked(
            offerTypeFilters, filter.key, setOfferTypeFilters,
            )}
          >
            {filter.name}
          </Checkbox>
          ))}
        </div>
        <button
          onClick={() => handleCheckAllFilters(offerTypeFilters, setOfferTypeFilters)}
          type="button"
          className="select-all-options--btn"
        >
          TOUT SÉLECTIONNER
        </button>
        </div>
        <div className="filters-row--wrapper">
        <div className="filter-buttons-row tag-buttons-row">
          {tagFiltersToDisplay.map((filter) => (filter))}
        </div>
        <button
          onClick={() => {
          setTagFiltersToDisplay([]);
          handleCheckAllFilters(tagFilters, setTagFilters)}
          }
          type="button"
          className="select-all-options--btn"
        >
          TOUT SÉLECTIONNER
        </button>
        </div>
        <CaliceoButton
        primaryButton
        classNames="mx-auto mb-5"
        onClick={() => applyFilters()}
        title="Appliquer mes choix"
        />
      </div>
      </div>
    </div>
    </div>
    <div className="offers-background--overlay" style={{ display: filtersAreVisible ? 'block' : 'none' }} />
    <div
    className="offers-text"
    dangerouslySetInnerHTML={{ __html: getOfferListingTexts(selectedUniverse, selectedUniFilter).categoryText }}
    />
    {(checkPromoOffers(offersView) && nbOffer !== 0) && (
    <>
      <h2 className="offer-listing--header">
      En promotion
      </h2>
      <div className="offers--row">
      {offersView.map((entity) => {
        if (entity.fieldTag[0]?.entity.tid !== 25 || !entity.status) return null;
        return (
        <OfferCard key={entity.nid} offer={entity} isPromo />
        );
      })}
      </div>
    </>
    )}
    {!promosSelected && (
    <>
      <h2 className="offer-listing--header">
      Nos offres dédiées pour vous
      </h2>
      <div className="offers--row">
      {offersView.map((entity) => {
        if (!entity.status) return null;
        return (
        <OfferCard key={entity.nid} offer={entity} isPromo={false} />
        );
      })}
      </div>
    </>
    )}
    {getOfferListingTexts(selectedUniverse, selectedUniFilter).secondCategoryText && (
    <div
      className="offers-text"
      dangerouslySetInnerHTML={{ __html: getOfferListingTexts(selectedUniverse, selectedUniFilter).secondCategoryText }}
    />
    )}
    <SEO title={meta_titre} description={result_descr} />
  </div>
  
  );
};

export default Offers;
