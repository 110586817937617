import { connect } from 'react-redux';

import { selectCenters } from 'redux/selectors/centers';
import { selectListedOffers } from 'redux/selectors/offers';

const mapStateToProps = (state) => ({
  centers: selectCenters(state),
  listedOffers: selectListedOffers(state),
});

export default connect(mapStateToProps);
