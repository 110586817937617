import React, { useState, useEffect, useMemo } from 'react';
import { Select, Checkbox } from 'antd';
import { navigate } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import CaliceoLink from 'components/buttons/CaliceoLink';
import ClockIcon from 'images/icons/clock-icon.png';

import { getCenterByDomainAccess } from 'utils/getCentersBy';
import useWindowWidth from 'hooks/use-window-width';
import timeConvert from 'utils/time';

import Cookies from 'universal-cookie';

const { Option } = Select;

const OfferCard = (props) => {
  const {
    offer, isPromo, centers, actions,
  } = props;

  const [isTimeType, setIsTimeType] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [activeVariant, setActiveVariant] = useState(null);
  const [productVariants, setProductVariants] = useState(null);

  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });

  const handleChangeVariant = (val) => {
    setActiveVariant(val);
  };

  useEffect(() => {
    setProductVariants(offer.queryFieldVariante?.entities);
    setActiveVariant(offer.queryFieldVariante?.entities[0]?.nid);
  }, []);

  const selectedVariant = useMemo(
    () => productVariants?.length && productVariants.find(
      (entity) => entity.nid === activeVariant,
    ), [productVariants, activeVariant],
  );

  const getBadgeForOffer = (selectedOffer) => {
    if (selectedOffer.fieldTag.length > 0) {
      return (
        <div className="badges">
          {/* {selectedOffer.fieldTag.map(({ entity }) => (
            <span className={`promo-offer--badge ${entity.tid === 25 ? '' : 'regular-tag'}`} key={entity.tid}>
              {entity.tid === 25 ? 'EN PROMO' : entity.name}
            </span>
          ))} */}
          <span
            className={`promo-offer--badge ${selectedOffer.fieldTag[0].entity.tid === 25 ? '' : 'regular-tag'}`}
            key={selectedOffer.fieldTag[0].entity.tid}
          >
            {selectedOffer.fieldTag[0].entity.tid === 25 ? 'EN PROMO' : selectedOffer.fieldTag[0].entity.name}
          </span>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    setSelectedCenter(getCenterByDomainAccess(centers, offer.fieldDomainAccess[0].entity.entityId));
  }, []);

  useEffect(() => {
    
    const checkIfTime = () => offer.queryFieldVariante?.entities.every((entity) => entity.fieldNombreSeance === 1);
    setIsTimeType(checkIfTime());
  }, []);

  const onChange = (e) => {
    const cookies = new Cookies();
    if(e.target.checked) {
      cookies.set('duo', 'ok', { path: '/' });
    }else {
      cookies.set('duo', '', { path: '/' });
    }
  };

  const navigateToOfferDetails = () => {
    if (windowWidth < 768) {
      navigate(`/produit${offer.path.alias}?aquaoOid=${selectedVariant?.fieldAquaoOid}`);
    }
  };
  
  const arr = selectedVariant?.fieldConsommablesList || [];
  
  return (
    <div className={`offer ${isPromo ? 'promo-offer' : ''}`} role="presentation" onClick={navigateToOfferDetails}>
      <div className="offer--image--wrapper">
        <LazyLoadImage
          effect="blur"
          className="offer--image"
          src={offer.fieldAccrocheFondImage?.entity.fieldMediaImage.derivative?.url}
          alt={offer.title}
        />
        {getBadgeForOffer(offer)}
      </div>
      <div className="offer--body">
        <h3 className="offer--title">{offer.title}</h3>
        <p className="offer--desc" dangerouslySetInnerHTML={{ __html: offer.fieldAccrocheTexte?.processed }} />
        <div className="offer--modifier--wrapper">
          {(
            // offer.queryFieldVariante?.entities.length > 1
            // && offer.queryFieldVariante?.entities[0]?.fieldNombreSeance
            // && offer.queryFieldVariante?.entities[0]?.fieldConsommablesList.length
            // && offer.queryFieldVariante?.entities[0]?.fieldConsommablesList[0]?.entity?.fieldRefEntite?.entity.fieldDureePrestation
            // && offer.queryFieldVariante?.entities[0]?.fieldConsommablesList[0]?.entity?.fieldRefEntite?.entity.fieldDureePrestation > 0
            offer.fieldMultipleVariante == true 
            && arr?.length > 0
            && !offer.title.includes('Chèque')
            )
            ? (
              
              <Select
                style={{ width: 120 }}
                onChange={handleChangeVariant}
                onClick={(e) => e.stopPropagation()}
                placeholder={
                  <img src={ClockIcon} alt="Clock icon" />
                }
                value={isTimeType ? timeConvert(selectedVariant?.fieldConsommablesList[0]?.entity?.fieldRefEntite?.entity?.fieldDureePrestation) : `${selectedVariant?.fieldNombreSeance === 1 ? `${selectedVariant?.fieldNombreSeance} séance` : `${selectedVariant?.fieldNombreSeance} séances `}`}
              >
                {offer.queryFieldVariante.entities.map((entity) => (
                  <Option
                    key={entity.nid}
                    value={entity.nid}
                  >
                    {isTimeType ? timeConvert(entity.fieldConsommablesList[0]?.entity?.fieldRefEntite?.entity?.fieldDureePrestation) : `${entity.fieldNombreSeance === 1 ? `${entity.fieldNombreSeance} séance` : `${entity.fieldNombreSeance} séances `}`}
                  </Option>
                ))}
              </Select>
            ) : null}
          {selectedVariant?.fieldDuo && <Checkbox className={`offer-checkbox ${isPromo ? 'promo-offer-checkbox' : ''}`} onChange={onChange}>En duo ?</Checkbox>}
        </div>
        <div className="offer--footer">
          <div className="offer--price--wrapper">
            {selectedVariant?.fieldPrixPromo && (
              <span className={`offer--price ${selectedVariant?.fieldPrixPromo ? 'has-promo' : ''}`}>
                {selectedVariant?.fieldPrixPromo}
                €
              </span>
            )}
            {selectedVariant?.fieldPrix && (
              <span className="offer--price">
                {offer.title.includes('Chèque') ? 'à partir de ' : null}
                {selectedVariant?.fieldPrix}
                €
              </span>
            )}
          </div>
          <CaliceoLink
            primaryButton
            classNames={`${isPromo ? 'promo-offer--btn' : ''} navigate-to-offer--btn`}
            title="Découvrir"
            url={
              `/produit${offer.path.alias}?aquaoOid=${selectedVariant?.fieldAquaoOid}`
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
