export const monoDuoFilterConstants = [
  {
    key: '0',
    name: 'seul',
    checked: false,
  },
  {
    key: '1',
    name: 'a deux',
    checked: false,
  },
];

export const typeFilterConstants = [
  {
    key: '0',
    name: 'unique',
    checked: true,
  },
  {
    key: '1',
    name: 'abonnement',
    checked: true,
  },
];

export const offerTypeFilterConstants = [
  {
    key: '0',
    name: 'POUR ME FAIRE PLAISIR',
    checked: true,
  },
  {
    key: '1',
    name: 'POUR OFFRIR',
    checked: true,
  },
];